import React from 'react'
import DesktopItem from '../DesktopItem'
import Icon from '../../../Icon'
import UnreadNotificationsIndicator from '../../../UnreadNotificationsIndicator'
import { t } from '@lingui/macro'

export default function DesktopMenu() {
  return (
    <div className="flex h-full text-center gap-6">
      <DesktopItem
        exact
        href="/"
        label={t`Inicio`}
        accessory={() => <Icon name="house2" size={18} className="text-gray-700" />}
      />

      <DesktopItem
        exact
        href="/news"
        label={t`Noticias`}
        accessory={() => <Icon name="novelty" size={18} className="text-gray-700" />}
      />

      <DesktopItem
        exact
        href="/webinars"
        label={t`Webinars`}
        accessory={() => <Icon name="industry" size={18} className="text-gray-700" />}
      />

      <DesktopItem
        exact
        href="/activities"
        label={t`Actividades`}
        accessory={() => <Icon name="calendar2" size={18} className="text-gray-700" />}
      />

      <DesktopItem
        href="/notifications"
        label={t`Notificaciones`}
        accessory={() => (
          <Icon name="bell2" size={18} className="text-gray-700" indicator={<UnreadNotificationsIndicator />} />
        )}
      />
    </div>
  )
}
